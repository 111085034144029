import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import MetaData from '../components/MetaData'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text, Box } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchBalancesHistory,
  setHighChRange,
  getOhlcPrice,
} from '../store/actions/userActions'
import Highcharts from 'highcharts/highstock'
import Indicators from 'highcharts/indicators/indicators-all.js'
import FullScreen from 'highcharts/modules/full-screen.js'
import StockTools from 'highcharts/modules/stock-tools.js'
import Exporting from 'highcharts/modules/exporting.js'
import DateAdapter from '@mui/lab/AdapterLuxon'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { DateTime } from 'luxon'
import { createTheme, TextField } from '@mui/material'
import styled from 'styled-components'
import WithTheme from '../helpers/WithTheme'

const BalancesTable = loadable(() =>
  import('../components/Table/BalancesTable')
)

const BoxShadow = styled(Box)`
  border-radius: 10px;
  overflow: hidden;
  padding: 15px 15px 8px 15px;
  box-shadow: 0 42px 54px rgba(65, 65, 101, 0.16);
`

const HighchartsReact = loadable(() => import('highcharts-react-official'))

const Balances = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const balHistory = useSelector(state => state.user.balHistory)
  const ohlcPrices = useSelector(state => state.user.ohlcPrices)
  const userCurr = useSelector(state => state.user.selectedCurr)
  // const currSign =  useSelector(state => state.user.currSign);
  const userHighChRange = useSelector(state => state.user.highChartsRange)
  const [windowWidth, setWindowWidth] = useState()
  const balData = []

  if (userCurr === 'BTC') {
    for (const bal in balHistory) {
      balData.push([
        balHistory[bal][0], // the date
        balHistory[bal][1],
      ])
    }
  } else {
    for (const bal in balHistory) {
      let balance = balHistory[bal][1]
      let balPrice = 0

      ohlcPrices.map(price => {
        return (balPrice = balance * price)
      })

      balData.push([
        balHistory[bal][0], // the date
        balPrice,
      ])
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWindowWidth(window.innerWidth)
      window.onresize = () => {
        setWindowWidth(window.innerWidth)
      }
    }
  }, [])

  const CURRENCY_MAP = [
    [`USD`, { exchange: `BITS`, market: `BTC/USD` }],
    [`EUR`, { exchange: `KRKN`, market: `BTC/EUR` }],
    [`ARS`, { exchange: `RPIO`, market: `BTC/ARS` }],
    [`AUD`, { exchange: `BTCM`, market: `BTC/AUD` }],
    [`BRL`, { exchange: `MERC`, market: `BTC/BRL` }],
    [`CAD`, { exchange: `KRKN`, market: `BTC/CAD` }],
    [`CHF`, { exchange: `KRKN`, market: `BTC/CHF` }],
    [`CNH`, { exchange: `BITF`, market: `BTC/CNHT` }],
    [`CZK`, { exchange: `MATE`, market: `BTC/CZK` }],
    [`DAI`, { exchange: `KRKN`, market: `BTC/DAI` }],
    [`ETH`, { exchange: `GDAX`, market: `ETH/BTC` }],
    [`GBP`, { exchange: `KRKN`, market: `BTC/GBP` }],
    [`IDR`, { exchange: `BIND`, market: `BTC/IDR` }],
    [`JPY`, { exchange: `KRKN`, market: `BTC/JPY` }],
    [`KRW`, { exchange: `BTHM`, market: `BTC/KRW` }],
    [`KZT`, { exchange: `EXMO`, market: `BTC/KZT` }],
    [`NGN`, { exchange: `BINA`, market: `BTC/NGN` }],
    [`PLN`, { exchange: `EXMO`, market: `BTC/PLN` }],
    [`RUB`, { exchange: `CXIO`, market: `BTC/RUB` }],
    [`SGD`, { exchange: `GMNI`, market: `BTC/SGD` }],
    [`TRY`, { exchange: `BINA`, market: `BTC/TRY` }],
    [`UAH`, { exchange: `BINA`, market: `BTC/UAH` }],
  ]

  // SET EXCH AND MKT FOR OHLC REQ
  let ohlcExchange
  let ohlcMarket

  for (const curr in CURRENCY_MAP) {
    if (CURRENCY_MAP[curr][0] === userCurr) {
      ohlcExchange = CURRENCY_MAP[curr][1].exchange
      ohlcMarket = CURRENCY_MAP[curr][1].market
    }
  }

  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ months: 1 }).toISODate()
  )
  const [endDate, setEndDate] = useState(DateTime.now().toISODate())

  useEffect(() => {
    dispatch(fetchBalancesHistory(startDate, endDate))
    if (
      typeof ohlcExchange !== 'undefined' &&
      typeof ohlcMarket !== 'undefined'
    ) {
      dispatch(getOhlcPrice(ohlcExchange, ohlcMarket, startDate, endDate))
    }
  }, [dispatch, startDate, endDate, ohlcExchange, ohlcMarket])

  const chartOptions = {
    chart: {
      type: 'area',
      height: windowWidth > 991 ? (9 / 16) * 100 + '%' : 400, // 16:9 ratio
      backgroundColor: userDarkMode ? '#191B20' : 'white',
      style: { fontFamily: 'CircularStd' },
      zoomType: 'x',
    },
    xAxis: {
      minRange: 24 * 3600 * 1000, // one day
      lineColor: userDarkMode ? '#303030' : '#CACACA',
    },
    yAxis: {
      gridLineColor: userDarkMode ? '#303030' : '#CACACA',
      lineColor: userDarkMode ? '#303030' : '#CACACA',
    },
    rangeSelector: {
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
          events: {
            click: function () {
              setRange(0)
            },
          },
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
          events: {
            click: function () {
              setRange(1)
            },
          },
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
          events: {
            click: function () {
              setRange(2)
            },
          },
        },
        {
          type: 'ytd',
          text: 'YTD',
          events: {
            click: function () {
              setRange(3)
            },
          },
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
          events: {
            click: function () {
              setRange(4)
            },
          },
        },
        {
          type: 'all',
          text: 'All',
          events: {
            click: function () {
              setRange(5)
            },
          },
        },
      ],
      inputEnabled: false,
      allButtonsEnabled: false,
      selected: userHighChRange !== null ? userHighChRange : 5,
      buttonTheme: {
        // styles for the buttons
        fill: 'none',
        stroke: 'none',
        'stroke-width': 0,
        r: 4,
        style: {
          color: userDarkMode ? '#CCCCCC' : '#191B20',
          fontWeight: 'bold',
          fontSize: '13px',
        },
        states: {
          hover: {
            fill: userDarkMode ? 'black' : '#CCCCCC',
          },
          select: {
            fill: '#3377C6',
            style: {
              color: 'white',
            },
          },
          // disabled: { ... }
        },
      },

      inputBoxBorderColor: 'transparent',
      inputStyle: {
        color: '#3377C6',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG', 'downloadSVG', 'printChart'],
        },
      },
    },

    title: {
      // text: selectedCurr.code + '/' + chartQuoteCurr.toUpperCase(),
      text: '',
      align: 'left',
      style: {
        color: userDarkMode ? '#FAFAFA' : 'black',
        'font-size': '21px',
        'font-weight': '900',
      },
    },

    tooltip: {
      split: true,
    },

    navigator: {
      outlineColor: userDarkMode ? '#303030' : '#CACACA',
      xAxis: {
        gridLineColor: userDarkMode ? '#303030' : '#CACACA',
      },
      series: {
        lineWidth: 0,
        fillOpacity: 0,
      },
    },

    scrollbar: {
      barBackgroundColor: '#CACACA',
      barBorderRadius: 0,
      barBorderWidth: 0,
      buttonBackgroundColor: userDarkMode ? '#303030' : '#CACACA',
      buttonBorderWidth: 0,
      buttonBorderRadius: 2,
      buttonArrowColor: userDarkMode ? 'white' : 'black',
      trackBackgroundColor: 'none',
      trackBorderWidth: 1,
      trackBorderRadius: 2,
      trackBorderColor: userDarkMode ? '#303030' : '#CACACA',
    },

    series: [
      {
        type: 'area',
        name: 'Balance',
        data: balData,
        color: '#FF4242',
      },
    ],
  }

  // IF check to avoid Highcharts error on gatsby build
  if (typeof window !== `undefined`) {
    Indicators(Highcharts)
    FullScreen(Highcharts)
    StockTools(Highcharts)
    Exporting(Highcharts)
  }

  const [range, setRange] = useState(null)

  useEffect(() => {
    if (range !== null) {
      dispatch(setHighChRange(range))
    }
  }, [dispatch, range])

  return (
    <>
      <MetaData page="default" /> 
      <PageWrapper headerDark footerDark>
        <Section bg={userDarkMode ? 'black' : 'bg'} pb={[0, null, null, 0]}>
          <Container className="mb-5">
            <Row>
              <Col>
                <Title
                  color={userDarkMode ? '#3986F7' : '#185398'}
                  fontSize={'28px !important'}
                  lineHeight={'28px !important'}
                  marginBottom={'28px !important'}
                  marginLeft={'0px !important'}
                  marginTop={3}
                  letterSpacing={'-0.02em'}
                >
                  {t('balances')}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <BoxShadow
                  style={{
                    backgroundColor: userDarkMode ? '#191B20' : 'white',
                  }}
                  mb={4}
                >
                  <Box style={{ marginBottom: 15 }}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        format="yyyy/MM/dd"
                        value={startDate}
                        views={['year', 'month', 'day']}
                        onChange={value => setStartDate(value.toISODate())}
                        renderInput={params => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <Text
                      variant="small"
                      style={{
                        display: 'inline-block',
                        color: userDarkMode ? '#FAFAFA' : 'black',
                      }}
                      className="mt-2 ml-3 mr-3"
                    >
                      to
                    </Text>

                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DatePicker
                        className="mr-3"
                        format="yyyy/MM/dd"
                        value={endDate}
                        views={['year', 'month', 'day']}
                        onChange={value => setEndDate(value.toISODate())}
                        renderInput={params => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={chartOptions}
                  />
                </BoxShadow>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg="12">
                <BalancesTable />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

const muiTheme = ({ userDarkMode }) =>
  createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: { fontFamily: 'CircularStd, sans-serif !important' },
          input: {
            color: userDarkMode ? '#FAFAFA !important' : 'black !important',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: userDarkMode ? '#FAFAFA' : '#191B20',
            fontFamily: 'CircularStd, sans-serif',
          },
          underline: {
            '&:before': { borderBottom: '2px solid transparent !important' },
            '&:after': { borderBottom: '2px solid #3377C6 !important' },
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: { toolbar: { backgroundColor: '#3377C6' } },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: { switchHeader: { color: '#1b5e20' } },
      },
      MuiPickersDay: {
        styleOverrides: { daySelected: { backgroundColor: '#3377C6' } },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'CircularStd, sans-serif !important',
            fontWeight: '400 !important',
          },
          h4: { fontFamily: 'CircularStd, sans-serif !important' },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'CircularStd, sans-serif !important',
            textTransform: 'capitalize !important',
          },
          textPrimary: { color: '#3377C6 !important' },
        },
      },
    },
    mixins: {},
    palette: {
      text: { hint: 'rgba(0, 0, 0, 0.38)' },
      mode: 'light',
      type: 'light',
    },
  })

export default WithTheme(muiTheme)(Balances)
